<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Apex Racing Team</h2>
      <div class="bar"></div>
      <p>
        A full page dedicated to the racing team, support provided to the
        riders, images, achievements and option to apply as a racer
      </p>
    </div>
  </div>
</section>
