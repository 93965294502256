<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Here's how we train</h2>
      <div class="bar"></div>
      <p>
        A full page dedicated to the formats, courses and structure of training
        provided by Apex with link to upcoming sessions
      </p>
    </div>
  </div>
</section>
