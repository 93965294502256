<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Our Upcoming Sessions</h2>
      <div class="bar"></div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
        ultrices gravida.
      </p>
    </div>

    <div class="row">
      <ng-container *ngIf="upcoming_events.length > 0">
        <div class="col-lg-4 col-md-6" *ngFor="let event of upcoming_events">
          <div
            class="single-blog-post cursor-pointer"
            (click)="viewEvent(event)"
          >
            <div class="image">
              <a routerLink="/single-blog"
                ><img src="assets/img/blog/image1.jpg" alt="image"
              /></a>
              <div class="category-btn">
                <a routerLink="/blog">MMRT</a>
              </div>
            </div>

            <div class="content">
              <ul class="post-meta">
                <li><i class="fa fa-calendar"></i> 12 March 2023</li>
                <!-- <li>
                            <i class="fa fa-comments"></i>
                            <a routerLink="/single-blog">3 Comment</a>
                          </li> -->
              </ul>
              <h3>
                <a routerLink="/single-blog">Event Name</a>
              </h3>
              <p>Water plan dolor sit amet consturisi velised quiLorem</p>
              <!-- <a routerLink="/single-blog" class="read-more">Read More</a> -->
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>

<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Past Sessions</h2>
      <div class="bar"></div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
        ultrices gravida.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/single-blog"
              ><img src="assets/img/blog/image1.jpg" alt="image"
            /></a>
            <div class="category-btn">
              <a routerLink="/blog">MMRT</a>
            </div>
          </div>

          <div class="content">
            <ul class="post-meta">
              <li><i class="fa fa-calendar"></i> 12 March 2023</li>
              <!-- <li>
                      <i class="fa fa-comments"></i>
                      <a routerLink="/single-blog">3 Comment</a>
                    </li> -->
            </ul>
            <h3>
              <a routerLink="/single-blog">Event Name</a>
            </h3>
            <p>Water plan dolor sit amet consturisi velised quiLorem</p>
            <a routerLink="/single-blog" class="read-more">Read More</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/single-blog"
              ><img src="assets/img/blog/image2.jpg" alt="image"
            /></a>
            <div class="category-btn">
              <a routerLink="/blog">Coimbatore</a>
            </div>
          </div>

          <div class="content">
            <ul class="post-meta">
              <li><i class="fa fa-calendar"></i> 18 March 2023</li>
              <!-- <li>
                      <i class="fa fa-comments"></i>
                      <a routerLink="/single-blog">6 Comment</a>
                    </li> -->
            </ul>
            <h3>
              <a routerLink="/single-blog">Event Name</a>
            </h3>
            <p>Water plan dolor sit amet consturisi velised quiLorem</p>
            <a routerLink="/single-blog" class="read-more">Read More</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/single-blog"
              ><img src="assets/img/blog/image3.jpg" alt="image"
            /></a>
            <div class="category-btn">
              <a routerLink="/blog">MMRT</a>
            </div>
          </div>

          <div class="content">
            <ul class="post-meta">
              <li><i class="fa fa-calendar"></i> 28 March 2023</li>
              <!-- <li>
                      <i class="fa fa-comments"></i>
                      <a routerLink="/single-blog">0 Comment</a>
                    </li> -->
            </ul>
            <h3>
              <a routerLink="/single-blog">Event Name </a>
            </h3>
            <p>Water plan dolor sit amet consturisi velised quiLorem</p>
            <a routerLink="/single-blog" class="read-more">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
