import { DataService } from 'src/app/services/data.service';
import { Component } from '@angular/core';


@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent {

  constructor(private dataService: DataService) { }
  contact = {
    name: "",
    email: "",
    mobile: "",
    message: ""
  }

  onSubmit() {
    // Make an API call to store_contact with the contact data
    this.dataService.storeContact(this.contact)
      .subscribe(
        (response) => {
          console.log('API response:', response);
          // Optionally, reset the form after successful submission

        },
        (error) => {
          console.error('API error:', error);
        }
      );
  }
}
