<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Blogs</h2>
      <div class="bar"></div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
        ultrices gravida.
      </p>
    </div>

    <div class="row">
      <ng-container *ngIf="blogs.length > 0">
        <div class="col-lg-4 col-md-6" *ngFor="let blog of blogs">
          <div class="single-blog-post cursor-pointer" (click)="viewBlog(blog)">
            <div class="image">
              <a routerLink="/single-blog"
                ><img src="assets/img/blog/image1.jpg" alt="image"
              /></a>
            </div>

            <div class="content">
              <ul class="post-meta">
                <li><i class="fa fa-calendar"></i> 12 March 2023</li>
                <!-- <li>
                              <i class="fa fa-comments"></i>
                              <a routerLink="/single-blog">3 Comment</a>
                            </li> -->
              </ul>
              <h3>
                <a routerLink="/single-blog">Blog Title</a>
              </h3>
              <p>Short description about the blog</p>
              <!-- <a routerLink="/single-blog" class="read-more">Read More</a> -->
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
