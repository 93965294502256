<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>About Apex Racing Academy</h2>
      <div class="bar"></div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
        ultrices gravida.
      </p>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <h3>Our process is simple, Our App is powerful</h3>
          <div class="bar"></div>
          <p>
            Apex Racing Academy is Bangalore's premier destination for aspiring
            racers and motorsport enthusiasts. Established with a passion for
            speed and precision, we offer world-class training programs designed
            to hone your racing skills, whether you're a beginner or a seasoned
            pro. Our mission is to nurture talent, instill discipline, and pave
            the way for the next generation of champions.
          </p>
          <p>
            If you are going to use a passage of Lorem Ipsum, you need to be
            sure there isn't anything embarrassing hidden in the middle of text.
            All the Lorem Ipsum generators on the Internet tend to repeat
            predefined chunks as necessary, making this the first true generator
            on the Internet.
          </p>
          <div class="about-btn">
            <a routerLink="/" class="default-btn">Download Now <span></span></a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="https://placehold.jp/600x600.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Our Vision</h2>
      <div class="bar"></div>
      <p>
        To become a leading hub for motorsport training in India, inspiring and
        developing future racing legends.
      </p>
    </div>
  </div>
</section>

<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Our Mission</h2>
      <div class="bar"></div>
      <p>
        Our mission is to provide top-tier racing education, foster a safe and
        competitive environment, and promote the spirit of motorsport. We aim to
        empower individuals with the skills, knowledge, and confidence needed to
        excel on the track and beyond.
      </p>
    </div>
  </div>
</section>

<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Why Choose Us?</h2>
      <div class="bar"></div>
      <p>
        Apex Racing Academy stands out for its commitment to excellence, safety,
        and personalized training. With access to top-tier instructors,
        cutting-edge facilities, and a proven track record of success, we
        provide an unparalleled learning experience for every motorsport
        enthusiast.
      </p>
    </div>
  </div>
</section>

<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Our Achievements</h2>
      <div class="bar"></div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
        ultrices gravida.
      </p>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4">
        <div class="single-team">
          <div class="image">
            <img src="assets/img/team/image1.jpg" alt="image" />
            <div class="content">
              <h3>Racer Name</h3>
              <p>Achievement</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="single-team">
          <div class="image">
            <img src="assets/img/team/image1.jpg" alt="image" />
            <div class="content">
              <h3>Racer Name</h3>
              <p>Achievement</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="single-team">
          <div class="image">
            <img src="assets/img/team/image1.jpg" alt="image" />

            <div class="content">
              <h3>Racer Name</h3>
              <p>Achievement</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="single-team">
          <div class="image">
            <img src="assets/img/team/image1.jpg" alt="image" />

            <div class="content">
              <h3>Racer Name</h3>
              <p>Achievement</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-wrapper">
  <div class="container">
    <div class="section-title">
      <h2>Frequently Asked Questions</h2>
      <div class="bar"></div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
        ultrices gravida.
      </p>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="faq-accordion-content">
          <div class="accordion" id="faqAccordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Who can join Apex Racing Academy?
                  <i class="fa fa-chevron-down"></i>
                </button>
              </div>

              <div
                id="collapseOne"
                class="collapse show"
                data-bs-parent="#faqAccordion"
              >
                <div class="card-body">
                  <p>
                    Our programs are open to all motorsport enthusiasts, from
                    beginners to experienced racers. We have courses tailored to
                    various skill levels.
                  </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTwo">
                <button
                  class="collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What types of vehicles do you provide for training?
                  <i class="fa fa-chevron-down"></i>
                </button>
              </div>

              <div
                id="collapseTwo"
                class="collapse"
                data-bs-parent="#faqAccordion"
              >
                <div class="card-body">
                  <p>
                    We offer a range of two-wheelers on rental basis. Riders can
                    also get their personal vehicles.
                  </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <button
                  class="collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Do I need prior racing experience to enroll?
                  <i class="fa fa-chevron-down"></i>
                </button>
              </div>

              <div
                id="collapseThree"
                class="collapse"
                data-bs-parent="#faqAccordion"
              >
                <div class="card-body">
                  <p>
                    No prior experience is necessary. We offer beginner courses
                    that cover the basics of racing and track safety.
                  </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFour">
                <button
                  class="collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  How long do the training programs last?
                  <i class="fa fa-chevron-down"></i>
                </button>
              </div>

              <div
                id="collapseFour"
                class="collapse"
                data-bs-parent="#faqAccordion"
              >
                <div class="card-body">
                  <p>
                    The duration varies depending on the course. We offer
                    single-day sessions, weekend programs, and extended courses
                    over several weeks.
                  </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFive">
                <button
                  class="collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Is safety gear provided during training?
                  <i class="fa fa-chevron-down"></i>
                </button>
              </div>

              <div
                id="collapseFive"
                class="collapse"
                data-bs-parent="#faqAccordion"
              >
                <div class="card-body">
                  <p>
                    Yes, we provide all necessary safety gear, including suits,
                    boots and gloves, for all training sessions on rental basis.
                    All riding gears are compulsory to enter into the track.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="faq-image">
          <img src="https://placehold.jp/600x600.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
</section>
