import { Component } from '@angular/core';

@Component({
  selector: 'app-blogs-home-page',
  templateUrl: './blogs-home-page.component.html',
  styleUrls: ['./blogs-home-page.component.scss'],
})
export class BlogsHomePageComponent {
  blogs = [1, 2, 3, 4, 5, 6, 7];

  viewBlog(blog: any) {}
}
