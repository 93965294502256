import { Component } from '@angular/core';
import { RoutesPath } from 'src/app/routes-constants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  aboutPage = '/' + RoutesPath.ABOUT;
  upcomingEvents = '/' + RoutesPath.EVENTS;
  tracks = '/' + RoutesPath.TRACKS;
  academy = '/' + RoutesPath.ACADEMY;
  racingteam = '/' + RoutesPath.RACINGTEAM;
  gallery = '/' + RoutesPath.GALLERY;
  contact = '/' + RoutesPath.CONTACT;
  blogs = '/' + RoutesPath.BLOG;

  facebook = '';
  instagram = '';
}
