import { Component } from '@angular/core';

@Component({
  selector: 'app-race-tracks-page',
  templateUrl: './race-tracks-page.component.html',
  styleUrls: ['./race-tracks-page.component.scss']
})
export class RaceTracksPageComponent {

}
