import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { RacingTeamComponent } from './pages/racing-team/racing-team.component';
import { GalleryPageComponent } from './pages/gallery-page/gallery-page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { LandingStatsComponent } from './components/landing-stats/landing-stats.component';
import { LandingAboutComponent } from './components/landing-about/landing-about.component';
import { LandingFeaturesComponent } from './components/landing-features/landing-features.component';
import { LandingUpcomingSessionsComponent } from './components/landing-upcoming-sessions/landing-upcoming-sessions.component';
import { LandingTestimonialsComponent } from './components/landing-testimonials/landing-testimonials.component';
import { LandingExpertTeamComponent } from './components/landing-expert-team/landing-expert-team.component';
import { LandingContactComponent } from './components/landing-contact/landing-contact.component';
import { LandingBlogComponent } from './components/landing-blog/landing-blog.component';
import { PrivacyPolicyComponent } from './pages/documents/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/documents/terms-conditions/terms-conditions.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UpcomingEventsPageComponent } from './pages/upcoming-events-page/upcoming-events-page.component';
import { BlogsHomePageComponent } from './pages/blogs-home-page/blogs-home-page.component';
import { RacingAcademyPageComponent } from './pages/racing-academy-page/racing-academy-page.component';
import { RacingTeamPageComponent } from './pages/racing-team-page/racing-team-page.component';
import { RaceTracksPageComponent } from './pages/race-tracks-page/race-tracks-page.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { EventDetailPageComponent } from './pages/event-detail-page/event-detail-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    AboutPageComponent,
    ContactPageComponent,
    RacingTeamComponent,
    GalleryPageComponent,
    NavbarComponent,
    FooterComponent,
    PreloaderComponent,
    LandingStatsComponent,
    LandingAboutComponent,
    LandingFeaturesComponent,
    LandingUpcomingSessionsComponent,
    LandingTestimonialsComponent,
    LandingExpertTeamComponent,
    LandingContactComponent,
    LandingBlogComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    UpcomingEventsPageComponent,
    BlogsHomePageComponent,
    RacingAcademyPageComponent,
    RacingTeamPageComponent,
    RaceTracksPageComponent,
    EventDetailPageComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, FormsModule, HttpClientModule],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
