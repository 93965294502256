import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { PrivacyPolicyComponent } from './pages/documents/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/documents/terms-conditions/terms-conditions.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { RoutesPath } from './routes-constants';
import { GalleryPageComponent } from './pages/gallery-page/gallery-page.component';
import { RacingAcademyPageComponent } from './pages/racing-academy-page/racing-academy-page.component';
import { RacingTeamComponent } from './pages/racing-team/racing-team.component';
import { UpcomingEventsPageComponent } from './pages/upcoming-events-page/upcoming-events-page.component';
import { BlogsHomePageComponent } from './pages/blogs-home-page/blogs-home-page.component';
import { RaceTracksPageComponent } from './pages/race-tracks-page/race-tracks-page.component';

const routes: Routes = [
  { path: RoutesPath.INDEX, component: LandingPageComponent },
  { path: RoutesPath.ABOUT, component: AboutPageComponent },
  { path: RoutesPath.TRACKS, component: RaceTracksPageComponent },
  { path: RoutesPath.GALLERY, component: GalleryPageComponent },
  { path: RoutesPath.CONTACT, component: ContactPageComponent },
  { path: RoutesPath.ACADEMY, component: RacingAcademyPageComponent },
  { path: RoutesPath.RACINGTEAM, component: RacingTeamComponent },
  { path: RoutesPath.EVENTS, component: UpcomingEventsPageComponent },
  { path: RoutesPath.BLOG, component: BlogsHomePageComponent },
  { path: RoutesPath.PRIVACY_POLICY, component: PrivacyPolicyComponent },
  { path: RoutesPath.TERMS_CONDITIONS, component: TermsConditionsComponent },
  { path: '*', component: LandingPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
