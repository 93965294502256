import { Component } from '@angular/core';

@Component({
  selector: 'app-upcoming-events-page',
  templateUrl: './upcoming-events-page.component.html',
  styleUrls: ['./upcoming-events-page.component.scss'],
})
export class UpcomingEventsPageComponent {
  upcoming_events = [1, 2, 3];

  viewEvent(event: any) {
    console.log(event);
  }
}
