<div class="main-banner">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="banner-content">
              <h1>We'll teach you a lesson you'll never forget</h1>
              <p>
                Join India's top racing academy and become a champion on the
                track. Learn from expert instructors and experience the thrill
                of speed.
              </p>
              <div class="banner-holder">
                <!-- <a routerLink="/"
                  ><img src="assets/img/store/1.png" alt="image"
                /></a>
                <a routerLink="/"
                  ><img src="assets/img/store/2.png" alt="image"
                /></a> -->
                <div class="about-btn">
                  <a routerLink="/" class="default-btn"
                    >Enroll for our upcoming batch <span></span
                  ></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="banner-image">
              <!-- <img src="assets/img/mobile.png" alt="image" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="fun-facts-area ptb-100">
  <app-landing-stats></app-landing-stats>
</section>

<section id="blog" class="blog-area pt-100 pb-70">
  <app-landing-upcoming-sessions></app-landing-upcoming-sessions>
</section>

<section id="about" class="about-area ptb-100">
  <app-landing-about></app-landing-about>
</section>

<section id="features" class="features-area pb-70">
  <app-landing-features></app-landing-features>
</section>

<section class="testimonials-area ptb-100">
  <app-landing-testimonials></app-landing-testimonials>
</section>

<!-- <section class="overview-area ptb-100">
  <app-overview></app-overview>
</section> -->

<!-- <section class="pricing-area pt-100 pb-70">
  <app-pricing></app-pricing>
</section> -->

<section class="team-area pt-100 pb-70">
  <app-landing-expert-team></app-landing-expert-team>
</section>

<!-- <section id="faq" class="faq-area ptb-100">
  <app-faq></app-faq>
</section> -->

<!-- <app-app-download></app-app-download> -->

<!-- <section id="blog" class="blog-area pt-100 pb-70">
  <app-landing-blog></app-landing-blog>
</section> -->

<section id="contact" class="contact-area ptb-100">
  <app-landing-contact></app-landing-contact>
</section>

<!-- <app-subscribe></app-subscribe> -->
