export class RoutesPath {
  public static INDEX: string = '';
  public static ABOUT: string = 'about';
  public static TRACKS: string = 'tracks-we-train';
  public static GALLERY: string = 'gallery';
  public static CONTACT: string = 'contact-us';
  public static ACADEMY: string = 'apex-racing-academy';
  public static RACINGTEAM: string = 'apex-racing-team';
  public static EVENTS: string = 'upcoming-events';
  public static BLOG: string = 'blogs';
  public static PRIVACY_POLICY: string = 'docs/privacy-policy';
  public static TERMS_CONDITIONS: string = 'docs/terms-and-conditions';
}
