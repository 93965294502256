<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <div class="logo">
      <!-- <a routerLink="/"><h3>Apex Racing Academy</h3></a> -->
      <a routerLink="/"
        ><img src="assets/img/logo/logo_2.png" alt="image" style="height: 68px"
      /></a>
    </div>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a [routerLink]="academy" class="nav-link"> Academy</a>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
            About
          </a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" [routerLink]="aboutPage"> About Us</a>
            </li>
            <li>
              <a class="dropdown-item" [routerLink]="upcomingEvents">
                Upcoming Events</a
              >
            </li>
            <li>
              <a class="dropdown-item" [routerLink]="racingteam">
                Apex Racing Team
              </a>
            </li>
            <!-- <li>
              <a class="dropdown-item" [routerLink]="tracks">
                Tracks we train
              </a>
            </li> -->

            <li>
              <a class="dropdown-item" [routerLink]="blogs"> Blogs </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a [routerLink]="gallery" class="nav-link">Gallery</a>
        </li>
        <li class="nav-item">
          <a [routerLink]="contact" class="nav-link">Contact</a>
        </li>
      </ul>

      <div class="others-option">
        <div class="d-flex align-items-center">
          <div class="contact-info">
            <div class="contact-info-content">
              <ul class="social">
                <li>
                  <a [routerLink]="facebook" target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a [routerLink]="instagram" target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="option-item">
            <a routerLink="/" class="default-btn">Register Now <span></span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
