<div class="container">
  <div class="section-title">
    <h2>About Apex Racing Academy</h2>
    <div class="bar"></div>
    <p>
      Welcome to Apex Racing Academy, India's premier destination for aspiring
      racing enthusiasts and professional riders.
    </p>
  </div>

  <div class="row align-items-center">
    <div class="col-lg-6 col-md-12">
      <div class="about-content">
        <h3>Our process is simple, Our Method is powerful</h3>
        <div class="bar"></div>
        <p>
          Established with a passion for motorsport excellence, our academy
          offers top-tier training programs designed to refine riding skills and
          enhance racing techniques. Our state-of-the-art facilities and a
          dedicated team of experienced instructors provide personalized
          coaching tailored to individual skill levels, from beginners to
          advanced racers.
        </p>
        <p>
          At Apex Racing Academy, we are committed to fostering a safe,
          competitive, and exhilarating environment where students can learn the
          intricacies of racecraft, bike dynamics, and strategic racing
          maneuvers.
        </p>
        <div class="about-btn">
          <a routerLink="/about" class="default-btn">Read More <span></span></a>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12">
      <div class="about-image">
        <img src="https://placehold.jp/700x700.png" alt="image" />
      </div>
    </div>
  </div>
</div>
